import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../Components/Layout';
import ProjectTile from '../Components/ProjectTile';
import MasonryLayout from '../Components/MasonryLayout';

const projects = ({ data }) => {

  const projectList = data.allMdx.edges.map(project => {
    return(
      <ProjectTile 
        link={project.node.slug}
        title={project.node.frontmatter.title} 
        tileImage={project.node.frontmatter.tileImage}
        alt={project.node.frontmatter.tileAlt}
        id={project.node.id}
        key={project.node.id}
      />
    )
  })
    return (
      <Layout>
        <MasonryLayout content={projectList} />
      </Layout>
    );
}

export default projects;

export const data = graphql` {
   allMdx (sort: {fields: [frontmatter___order], order: DESC}) {
    edges {
      node {
        slug
        id
        frontmatter {
          title
          tileAlt
          tileImage {
            childImageSharp {
              gatsbyImageData(
                width: 1000
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  }
}`
